
<template>
  <div class="content">
    <div class="brand">
      <div class="name">{{ brandName }}</div>
      <div class="time">{{ createTime }}</div>
    </div>
    <FlavorItem :show-select="false" :flavor="flavorInfo"/>

    <div class="flavor-info">
      <InfoRow  v-for="(item,index) in scanInfo" :key="index" :label="item.label" :value="item.value"/>
    </div>
  </div>
</template>
<script>
import FlavorItem from "@/components/FlavorItem.vue";
import FlavorInfo from "@/components/FlavorInfo.vue";
import InfoRow from "@/components/InfoRow.vue";

export default {
  name: "BrandRecordItem",
  components: { InfoRow, FlavorInfo, FlavorItem },
  props: {
    brandName: {
      type:String,
      default:""
    },
    createTime: {
      type:String,
      default:""
    },
    flavorInfo :{
      type:Object,
      default:()=> {}
    },
    scanInfo: {
      type:Array,
      default:()=> {
        return []
      }
    }
  }
};
</script>


<style scoped lang="less">
.content {
  display: flex;
  flex-direction: column;
  .brand {
    display: flex;
    align-items: center;
    .name {
      flex: 1;
      font-size:18px;
      font-weight: bold;
      color: #555555;
    }
    .time {
      flex: 1;
      text-align: right;
      font-size:14px;
      color: #999999;
    }
  }
  .flavor-info {
    margin-top:10px;
    display: flex;
    flex-direction: column;
  }
}
</style>