<template>
  <div class="container">
    <div class="header">
      <van-icon
          class="header-return"
          name="arrow-left"
          size="25"
          color="#bcbec0"
          @click="onClickHeaderReturn"
      />
      My Record
    </div>

    <div class="content">

      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          pulling-text="Pull down to refresh"
          loading-text="Loading..."
          success-text="Refresh success"
          loosing-text="Release to refresh"
      >
        <van-list
            v-if="!isEmpty"
            v-model="loading"
            :finished="finished"
            finished-text="No more data"
            loading-text="Loading more..."
            :error.sync="error"
            error-text="Load fail! Click reload"
            @load="onLoad"
        >
          <div v-for="(item,index) in recordList" :key="index">
            <BrandRecordItem
                :brand-name="item.brandName"
                :create-time="item.createTime"
                :flavor-info="item.flavor"
                :scan-info="item.scanInfo"
                style="margin:10px 15px 0 15px"/>
            <div style="height:6px;background: #F1F2F2; margin:20px 0 0 0"></div>
          </div>
        </van-list>
        <div class="no-data" v-else @click="onLoad">
          <div class="no-data">
            <img src="@/assets/icons/brand/icon_no_brand_data.png" style="width:150px;height:150px;"/>
            <span style="margin-top:20px;margin-bottom:60px;color: #999999">No data</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import BrandRecordItem from "@/components/BrandRecordItem.vue";
import HistoryCurve from "@/views/HistoryCurve.vue";
import produce from "@/api/produce";
import log from "@/util/log";

export default {
  name: "BrandRecord",
  components: { HistoryCurve, BrandRecordItem },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      pageSize: 10,
      isFirst : true,
      recordList: [],
      isEmpty: false
    };
  },
  methods: {
    onClickHeaderReturn() {
      this.$router.go(-1);
    },
    async onRefresh() {
      this.refreshing = true;
      this.onLoad();
    },
    async onLoad() {
      if (this.refreshing) {
        this.pageNum = 1;
        this.historyList = [];
      }
      try {
        const resp = await produce.scanBrandRecordList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }, this.isFirst);
        const { data, code, message, total } = resp;
        if (code !== 200) {
          throw message;
          return;
        }
        const remoteList = data.map(item => {
          const record = {
            id: item.id,
            brandName: item.brandName,
            createTime: item.createTime,
            flavor: {
              flavorName: item.flavorName,
              flavorPicture: item.flavorPicture,
              labelList: item.labelName.split(",").map(item => {
                return { labelName: item };
              })
            }
          };

          if (item.scanType === 1) { //包装盒
            record.scanInfo = [
              { label: "Boxes quantity", value: item.boxQuantity },
              { label: "Quantity", value: item.quantity },
              { label: "Device model", value: item.deviceTypeName }
            ];
          } else if (item.scanType === 2) { //单台设备
            record.scanInfo = [
              { label: "Quantity", value: item.quantity },
              { label: "Device model", value: item.deviceTypeName }
            ];
          }
          return record;
        });
        this.recordList = this.recordList .concat(remoteList)
        this.isEmpty = (total === 0)
        if (total != 0) {
          this.isFirst = false
        }
        //刷新结束
        if (this.refreshing) {
          this.refreshing = false;
        } else {
          this.loading = false;
        }
        if (this.recordList.length >= total) {
          this.finished = true;
        }
        this.pageNum += 1;

      } catch (e) {
        log(e);
        if (e instanceof String) {
          this.$dialog.alert({ message });
        } else if (e instanceof Error) {
          this.$dialog.alert({ message: e.message });
        }

        this.loading = false;
        this.error = true;
      }

    }
  }
};
</script>


<style scoped lang="less">
.container{

  height: 100%;
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

}
.header {
  width: 100%;
  height: 60px;
  min-height: 60px;
  background: #f1f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #545554;

  .header-return {
    position: absolute;
    left: 10px;
  }

}

.content {
  height: 100%;
  overflow-y: auto;
  background: #f6f6f6;
}


.no-data {
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: white;
  color: #939598;

}
</style>